export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~8],
		"/account": [9,[2]],
		"/account/contact": [10,[2]],
		"/account/games": [11,[2]],
		"/account/password": [12,[2]],
		"/account/subscription": [13,[2]],
		"/auth/login": [~14,[3]],
		"/auth/logout": [~15,[3]],
		"/auth/redirect": [~16,[3]],
		"/auth/register": [~17,[3]],
		"/auth/unlock": [~18,[3]],
		"/contact": [19,[4]],
		"/developer": [20],
		"/faq": [21,[5]],
		"/info": [22,[6]],
		"/lab": [23],
		"/play/[game]": [~24,[7]],
		"/privacy": [25],
		"/terms": [26]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';